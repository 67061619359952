<template>
	<div @click="onClick" class="setting">
		<img ref="img" src="img/ui/icon-setting.svg">
	</div>
</template>

<script>
import { gsap, Quad } from 'gsap/all'
import router from '@/router'

export default {
	name: 'SettingButton',
	mounted () {
		gsap.set(this.$refs.img, { scale: 0.8, opacity: 0, y: 20, rotation: '600deg' })
		this.show()
	},
	methods: {
		onClick () {
			router.push('/setting')
		},
		show () {
			gsap.to(this.$refs.img, { opacity: 1, y: 0, scale: 1, rotation: '0deg', delay: 0.5, ease: Quad.easeOut, duration: 0.5 })
		},
		hide () {
			gsap.to(this.$refs.img, { opacity: 0, y: -20, scale: 1.1, rotation: '-600deg', delay: 0, ease: Quad.easeOut, duration: 0.5 })
		}
	}
}
</script>

<style lang="stylus" scoped>
.setting
	img
		transform-origin center center
		width 32px
		height 32px
		opacity 0
		will-change opacity transform
	color #000
	width 20px
	height 20px
	cursor pointer
	position absolute
	top 20px
	right 30px
	user-select none
	&:hover
		color #FAFAFA

</style>
